<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-text-field
              v-model="item.id"
              label="User Id"
              disabled
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="item.name"
              label="Username"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="item.password"
              label="Password"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-switch
              v-model="item.active"
              label="Activo"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-list
              subheader
              two-line
              flat
            >
              <v-subheader>Dispositivos disponibles</v-subheader>

              <v-list-item-group
                v-model="deviceList"
                multiple
              >
                <v-list-item
                  v-for="(item, i) in allDevices"
                  :key="i"
                  :value="item.id"
                >
              
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active||check(item.id)"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-subtitle>Id: {{item.id}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>


                </v-list-item>
              </v-list-item-group>
            </v-list>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.user.roleid == 1">
          <v-col cols="12">
            <span>Picked: {{ deviceList }}</span>
          </v-col>
            <v-col>
              <v-form v-model="isValid">
              <v-text-field label="Extra devices" hint="Ids comma separated" id="extraDevices"
               :rules="[rules.intCommaSeparated]"
               validate-on-blur
               v-model="extraDeviceText"
               ></v-text-field>
              </v-form>
            </v-col>
          
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props:{
        editedItem:{
            type: Object,
            default: ()=>({})
        },
        allDevices:{
          type: Array,
          default: () => {[]}
        }
    },
    data:()=>({
        //internalDialog: false,
        item: {},
        deviceList:[],
        extraDeviceList:[],
        originalExtraDeviceList:[],
        extraDeviceText:'',
        isValid:false,
        rules:{
          intCommaSeparated(value){
            var regex = /^[0-9]+(,[0-9]+)*$/;
            var result = value=="";
            if(!result)
              result = regex.test(value)

/*
            if(result && this != undefined)
              if(value == '')
                this.extraDeviceList = [];
              else
                this.extraDeviceList = value.split(',');
*/
            return result;
          }
        }
    }),
    mounted(){
      //this.item = Object.assign({}, this.editedItem);
      //this.internalDialog = this.dialog;
      this.deviceList = [];
      this.item = Object.assign({}, this.editedItem);
      this.initialize();
    },
    watch: { 
      editedItem: function(newVal) { // watch it
        this.deviceList = [];
        //console.log('Prop item changed: ', newVal, ' | was: ', oldVal);
        this.item = Object.assign({}, newVal);
        this.initialize();
      }
    },
    computed:{
      
      editedIndex(){
        return this.editedItem.id == undefined ? -1 : this.editedItem.id;
      },
      formTitle () {
        return this.editedIndex == -1 ? 'New Item' : 'Edit Item';
      },
      currentUserId(){
        return parseInt(this.$route.params?.id || this.$store.state.user.id);
      }
    },

    methods:{
      ...mapActions(['addUser','showSnackbar']),
      close () {
        this.extraDeviceList = [];
        this.originalExtraDeviceList = [];
          this.$emit('close');
      },
      save () {
        if(this.extraDeviceText != ''){
          this.extraDeviceList = this.extraDeviceText.split(',');
        }
        this.originalExtraDeviceList.forEach(it =>{ 
            var idx = this.deviceList.indexOf(it);
            this.deviceList.splice(idx,1);
        });

        this.extraDeviceList.forEach(it => this.deviceList.push(parseInt(it)));

        //console.log(this.deviceList);

        

        var user = {
            name: this.item.name,
            email: this.item.name,
            password: this.item.password,
            active: this.item.active,
            devices: this.deviceList,
            roleid: 3,
            userid: this.currentUserId 
          };

        if(this.item.id != undefined){
          //edit a user
          user.id = this.item.id;
        }

        console.log(user);
        this.addUser(user).then((res)=>{
                if(res != undefined)
                this.showSnackbar({
                    showing:true,
                    message: 'User '+res.data.name +' updated.',
                    color:'success'
                });
            }).catch(err =>{ 
                console.log(err);
                    if(err.data != undefined && err.data.errors != undefined)
                        this.showSnackbar({
                            showing:true,
                            message: err.data.errors[0],
                            color:'error'
                        });
                    if(err.message != undefined)
                        this.showSnackbar({
                            showing:true,
                            message: err.message,
                            color:'error'
                        });
                    else
                        this.showSnackbar({
                            showing:true,
                            message: 'Error updating user.',
                            color:'error'
                        });
            });

        this.close();
      },

      check(id){
        return this.deviceList.some(it => it == id);
      },

      initialize(){
        if(this.item.devices == undefined)
          this.deviceList = [];
        else
          this.item.devices.forEach(dev => {
            this.deviceList.push(dev.id);
          });

        if(this.item.devices != undefined){
          var extra = this.item.devices.filter(dev => !this.allDevices.some(alldev => dev.id == alldev.id));
          //console.log(this.allDevices.filter(dev => this.item.devices.includes(alldev => dev.id === alldev.id)));
          extra.forEach(ex => {
            this.extraDeviceList.push(ex.id);
            this.originalExtraDeviceList.push(ex.id);
          });
          this.extraDeviceText = this.extraDeviceList.join();
        }
      }
    }
}
</script>

<style>

</style>